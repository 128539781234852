<template>
  <div class="site-cont">
    <div class="search-store-title">查找维修站</div>
    <div class="search-wrap">
      <cube-select class="select" placeholder="请选择手机品牌"
        v-model="form.brand"
        :options="brands">
      </cube-select>
      <div class="bnt-list">
        <div class="app-select-city">
          <div class="asc-filter-cnt icon-flex" @click="showProvince">
            <setting-config theme="outline" fill="#333" :strokeWidth="4"/>
            <span>{{form.province}}</span>
          </div>
        </div>
        <div class="near-me-wrap" @click="showCity">
          <div class="near-me icon-flex">
            <navigation theme="outline" fill="#333" :strokeWidth="4"/>
            <span>{{city.name}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="map-wrap">
      <div class="map-list-one">
        <div class="map-list-one-cnt">
          <div class="map-list-one-title">{{city.name}}</div>
          <p class="number-stores">{{city.list.length}} 家门店</p>
        </div>
      </div>
      <ul class="map-list" >
        <li v-for="(item, index) in city.list" :key="index" @click="goInfo(item)">
          <div class="map-list-cnt">
            <div class="map-list-title">
              <div class="font-title">{{item.name}}</div>
            </div>
            <div class="map-list-address">{{item.address}}</div>
            <div class="map-list-phone">{{item.mobile}}</div>
          </div>
        </li>
      </ul>

      <!-- <div class="list-pager-wrap">
        <div class="list-pager-title">已展示10条记录 / 共100条</div>
        <div class="list-pager-bnt">加载更多</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import {Navigation, SettingConfig} from '@icon-park/vue'

export default {
  components: {
    Navigation,
    SettingConfig
  },
  data () {
    return {
      brands: [],
      form: {
        "brand":"华为",
		    "province":"北京市"
      },
      provinceList: [], // 省份列表
      siteList: [], // 门店列表
      city: {
        name: '北京市',
        list: []
      }
    }
  },
  mounted () {
    this.getBrand();
    // this.getInfo();
  },
  methods: {
    /**
     * 市选择
     */
    showCity () {
      if (!this.picker2) {
        this.picker2 = this.$createPicker({
          title: '选择城市',
          data: [this.siteList],
          onSelect: this.selectCity
        })
      }
      this.picker2.show()
    },
    selectCity (val) {
      console.log(val)
      const vm = this;
      this.siteList.forEach(e => {
        if(e.value == val[0]){
          vm.city = {
            name: val[0],
            list: e.maintenances
          }
        }
      })
    },
    /**
     * 省选择
     */
    showProvince () {
      if (!this.picker) {
        this.picker = this.$createPicker({
          title: '选择城市',
          data: [this.provinceList.map(e => {
            return {
              text: e, 
              value: e
            }
          })],
          onSelect: this.selectProvince
        })
      }
      this.picker.show()
    },
    selectProvince (val) {
      this.form.province = val[0];
      this.getInfo()
    },
    /**
     * 获取省份列表
     */
    getProvince () {
      const vm = this;
      this.provinceList = [];
      vm.brands.forEach(e => {
        if(e.value == vm.form.brand){
          vm.provinceList = e.provinces;
          vm.form.province = vm.provinceList[0];
          vm.getInfo();
        }
      })
    },
    /**
     * 获取品牌
     */
    getBrand () {
      const vm = this;
      let postData = {}
      vm.$store.commit('updateLoader', true);
      this.$axios({
        method: 'post',
        baseURL: this.$init.apis.default,
        url: "/dic/maintenance/queryBrand",
        data: postData,
        timeout: 600000,
      }).then(function(response) {
          vm.$store.commit('updateLoader', false);
          vm.brands = [];
          if(response.data.body && response.data.body.brands) {
            vm.brands = response.data.body.brands.map(e => {
              return {
                text: e.brandName,
                value: e.brandName,
                provinces: e.provinces,
              }
            })
            vm.getProvince()
          }
        })
        .catch(function(err) {
          // vm.$store.commit('updateLoader', false);
        });
    },
    /**
     * 获取门店列表
     */
    getInfo () {
      const vm = this;
      let postData = {...this.form}
      vm.$store.commit('updateLoader', true);
      vm.city = {
        name: '',
        list: []
      }
      this.$axios({
        method: 'post',
        baseURL: this.$init.apis.default,
        url: "/dic/maintenance/queryMaintenance",
        data: postData,
        timeout: 600000,
      }).then(function(response) {
          vm.$store.commit('updateLoader', false);
          vm.siteList = []
          if(response.data.body && response.data.body.cityList) {
            vm.siteList = response.data.body.cityList.map(e => {
              return {
                text: e.cityName,
                value: e.cityName,
                maintenances: e.maintenances,
              }
            })
            vm.city = {
              name: vm.siteList[0].text,
              list: vm.siteList[0].maintenances
            }
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    goInfo (obj) {
      this.$router.push('/query/siteInfo/' + obj.id)
    }
  },
}
</script>

<style lang="scss" scoped>
.site-cont{
  min-height: 100.1vh;
  box-sizing: border-box;
  padding: 80px 0;
}
.search-store-title{
  text-align: center;
  font-size: 52px;
  line-height: 64px;
  color: #000;
}
.site-cont .select{
  border: none;
  &::after{
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
}
.search-wrap{
  padding: 40px 32px 0;
}

.bnt-list{
  padding-top: 48px;
  line-height: 60px;
  font-size: 24px;
  display: flex;
  .app-select-city{
    flex: 10;
    color: #000;
  }
  .near-me-wrap{
    margin-left: 20px;
    background: #f5f5f5;
    border-radius: 60px;
    padding: 0 40px;
    text-align: center;
    color: rgba(0,0,0,0.52);
  }
}
.map-wrap{
  margin-top: 40px;
}

.map-list-title{
  font-size: 32px;
  line-height: 48px;
  color: #000;
}
.map-list-address{
  padding-top: 32px;
  font-size: 28px;
  line-height: 48px;
  color: rgba(0,0,0,0.5);
}
.map-list-phone{
  padding-top: 32px;
  font-size: 28px;
  line-height: 48px;
  color: rgba(0,0,0,0.5);
}
.map-list-one{
  padding: 32px;
  font-size: 28px;
  line-height: 48px;
  color: rgba(0,0,0,0.5);
  border-bottom: 1px solid #eaeaea;
}
.map-list{
  li{
    padding: 48px 32px;
    border-bottom: 1px solid #eaeaea;
  }
}
.list-pager-wrap{
  padding-top: 80px;
  text-align: center;
  .list-pager-title{
    font-size: 32px;
    margin-bottom: 60px;
    color: rgba(0,0,0,0.5);
  }
  .list-pager-bnt{
    margin: 0 auto;
    border: 4px solid rgba(0,0,0,0.30);
    border-radius: 48px;
    display: inline-block;
    font-size: 26px;
    color: #000;
    letter-spacing: 0;
    line-height: 32px;
    padding: 24px 48px;
    font-weight: bold;
  }
}
</style>
